<template>
  <view-item title="喷涂经营报告">
    <template #operation>
      <en-button type="primary" @click="operation.print.click">打印</en-button>
      <button-delete
        v-if="isEnoch && (form.data.status?.code === 'C' || form.data.status?.code === 'SA' || form.data.status?.code === 'TA')"
        content="确定退回吗？"
        :method="operation.reverse.click"
      >
        退回
      </button-delete>

      <button-ajax v-if="form.data.status?.code === 'P' && !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_AUDIT" :method="operation.commit.click">
        审核提交
      </button-ajax>

      <button-ajax
        v-if="form.data.status?.code === 'C' && !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_SERVICE_AUDIT"
        :method="operation.audit.service.click"
      >
        审批(管)
      </button-ajax>

      <button-ajax
        v-if="form.data.status?.code === 'SA' && !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_TECHNICAL_AUDIT"
        :method="operation.audit.technical.click"
      >
        审批(技)
      </button-ajax>

      <button-ajax
        v-if="form.data.status?.code === 'TA' && !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_MANAGEMENT_AUDIT"
        :method="operation.audit.management.click"
      >
        审批(运)
      </button-ajax>

      <button-ajax :disabled="form.loading || form.disabled" :method="operation.submit.click">保存</button-ajax>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="
          isEnoch
            ? {
                action: 'GET /enospray/statement/paint/business/report/enoch/query',
                params: (params) => (params.payload = { reported: true })
              }
            : {
                action: 'GET /enospray/statement/paint/business/report/query',
                params: (params) => (params.payload = { reported: true })
              }
        "
        :props="{ start: 'startDate', end: 'endDate' }"
        @row-click="manifest.row.click"
        @expand-click="dialog.visible = true"
      >
        <en-table-column label="单据号" prop="serialNo"></en-table-column>
        <en-table-column label="网点名称" prop="tenantShortName"></en-table-column>
        <en-table-column label="单据状态" prop="reportStatus.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="单据日期">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate" class="w-full"></en-date-picker>
          </en-form-item>
          <en-form-item label="网点">
            <select-maintain
              v-model="data.tenantId"
              :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
              :props="{ label: 'shortName', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="领/退料单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedBy"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="配件编号">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="配件名称">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex items-center gap-6">
        <div class="flex gap-4">
          <span>网点名称：{{ form.data.tenant?.name }}</span>
          <span>月份：{{ `${Number(form.data.endDate?.split('-')[1])}月` }}</span>
          <span>钣面数：{{ form.data.totalSquare }}</span>
          <span>入网时间：{{ formatDate(form.data.tenant?.joinDate) }}</span>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane label="分析报告" name="report">
                <en-scrollbar :height="height - 55" view-class="grid grid-cols-2 gap-6">
                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>钣面数: {{ formatNumber(square.summary.total, 2) }}</span>
                      <span>年度平均: {{ formatNumber(square.summary.average, 2) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: square.data },
                        series: [{ type: 'line', name: '钣面数', smooth: true, label: { show: true } }]
                      }"
                      class="h-100"
                    ></en-chart>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>成本管理: {{ formatPercent(cost.summary.total) }}</span>
                      <span>达标状态: {{ cost.summary.status }}</span>
                      <span>年度平均: {{ formatPercent(cost.summary.average) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: {
                          source: [
                            ['日期', ...cost.data.map((item) => item.label)],
                            ['当月成本', ...cost.data.map((item) => item.value)],
                            ['达标成本', ...cost.data.map(() => 20)]
                          ]
                        },
                        series: [
                          { type: 'line', name: '当月成本', smooth: true, seriesLayoutBy: 'row', label: { show: true } },
                          { type: 'line', name: '达标成本', smooth: true, seriesLayoutBy: 'row', color: 'red' }
                        ]
                      }"
                      class="h-100"
                    ></en-chart>

                    <div>
                      <en-button type="primary" text :disabled="store.attribute.GDSCCALMTD.value !== 'WACC'" @click="cost.check.click">
                        点击查看用料分析
                      </en-button>
                    </div>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>质保激活: {{ formatPercent(warranty.summary.total) }}</span>
                      <span>达标状态: {{ warranty.summary.status }}</span>
                      <span>年度平均: {{ formatPercent(warranty.summary.average) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: warranty.data },
                        series: [{ type: 'line', name: '质保激活', smooth: true, label: { show: true } }]
                      }"
                      class="h-80"
                    ></en-chart>
                  </en-card>

                  <en-card
                    v-if="form.data.businessSettlementPolicy && !['OS', 'NA'].includes(form.data.businessSettlementPolicy.code)"
                    body-class="flex flex-col divide-x"
                  >
                    <div class="flex items-center justify-between">
                      <span>商务政策: {{ formatPercent(policy.summary.total) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: {
                          source: [
                            ['日期', ...policy.data.map((item) => item.label)],
                            ['商务政策(元)', ...policy.data.map((item) => item.value)],
                            ['调整值', ...policyAdjust.data.map((item) => item.value)]
                          ]
                        },
                        series: [
                          { type: 'line', name: '商务政策(元)', smooth: true, seriesLayoutBy: 'row', label: { show: true } },
                          { type: 'scatter', name: '调整值', seriesLayoutBy: 'row', color: 'red', label: { show: true, position: 'left' } }
                        ]
                      }"
                      class="h-80"
                    ></en-chart>

                    <div>
                      <en-button
                        v-if="isEnoch && store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_AUDIT"
                        type="primary"
                        text
                        @click="policy.adjust.click"
                      >
                        调整值
                      </en-button>
                    </div>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>扫码枪使用率: {{ formatPercent(autoCount.summary.total) }}</span>
                      <span>达标状态: {{ autoCount.summary.status }}</span>
                      <span>年度平均: {{ formatPercent(autoCount.summary.average) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: autoCount.data },
                        series: [{ type: 'line', name: '扫码枪使用率', smooth: true, label: { show: true } }]
                      }"
                      class="h-80"
                    ></en-chart>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>返工控制: {{ formatPercent(rework.summary.total) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: rework.data },
                        series: [{ type: 'line', name: '扫码枪使用率', smooth: true, label: { show: true } }]
                      }"
                      class="h-80"
                    ></en-chart>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>客户满意度: {{ formatPercent(score.summary.total) }}</span>
                      <span>回访数量: {{ formatPercent(score.summary.total) }}</span>
                      <span>年度平均: {{ formatPercent(score.summary.total) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: score.data },
                        series: [{ type: 'line', name: '客户满意度', smooth: true, label: { show: true } }]
                      }"
                      class="h-80"
                    ></en-chart>
                  </en-card>

                  <en-card
                    v-if="form.data.businessSettlementPolicy && ['PX', 'DT'].includes(form.data.businessSettlementPolicy.code)"
                    body-class="flex flex-col divide-x"
                  >
                    <div class="flex items-center justify-between">
                      <span>赠送钣面: {{ formatNumber(donate.summary.total) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: { source: donate.data },
                        series: [{ type: 'line', name: '赠送钣面', smooth: true, label: { show: true } }]
                      }"
                      class="h-80"
                    ></en-chart>
                  </en-card>

                  <en-card
                    v-if="form.data.businessSettlementPolicy && !['OS'].includes(form.data.businessSettlementPolicy.code)"
                    body-class="flex flex-col divide-x"
                  >
                    <div class="flex items-center justify-between">
                      <span>团队绩效: {{ formatNumber(performance.summary.total, 1) }}</span>
                    </div>

                    <en-chart
                      :option="{
                        legend: {},
                        tooltip: { trigger: 'axis' },
                        xAxis: { type: 'category' },
                        yAxis: { type: 'value' },
                        dataset: {
                          source: [
                            ['日期', ...performance.data.map((item) => item.label)],
                            ['团队绩效(元)', ...performance.data.map((item) => item.value)],
                            ['调整值', ...adjust.data.map((item) => item.value)]
                          ]
                        },
                        series: [
                          { type: 'line', name: '团队绩效(元)', smooth: true, seriesLayoutBy: 'row', label: { show: true } },
                          { type: 'scatter', name: '调整值', seriesLayoutBy: 'row', color: 'red', label: { show: true, position: 'left' } }
                        ]
                      }"
                      class="h-80"
                    ></en-chart>

                    <div>
                      <en-button
                        v-if="isEnoch && store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_AUDIT"
                        type="primary"
                        text
                        :disabled="
                          form.data.performanceSendPolicy?.code === 'Q' &&
                          form.data.endDate &&
                          ![3, 6, 9, 12].includes(Number(form.data.endDate.split('-')[1]))
                        "
                        @click="performance.adjust.click"
                      >
                        调整值
                      </en-button>
                    </div>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>仓储管理</span>
                    </div>

                    <en-form label-position="left" label-width="80">
                      <en-form-item label="分值">
                        <en-input :model-value="form.warehouseScore" disabled></en-input>
                      </en-form-item>
                      <en-form-item label="达标状态">
                        <div class="grid grid-cols-2">
                          <span>出入库及时性</span>
                          <en-radio-group
                            :model-value="(form.data.warehouses as Standard)?.STIOIT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.warehouses.radio.change('STIOIT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>本月是否盘库</span>
                          <en-radio-group
                            :model-value="(form.data.warehouses as Standard)?.STTIT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.warehouses.radio.change('STTIT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>仓库盘库正常</span>
                          <en-radio-group
                            :model-value="(form.data.warehouses as Standard)?.STRSTT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED || (form.data.warehouses as Standard)?.STTIT === 'N'"
                            @change="form.warehouses.radio.change('STRSTT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>调漆间盘库正常</span>
                          <en-radio-group
                            :model-value="(form.data.warehouses as Standard)?.WSHSTT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED || (form.data.warehouses as Standard)?.STTIT === 'N'"
                            @change="form.warehouses.radio.change('WSHSTT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>
                        </div>
                      </en-form-item>
                      <en-form-item label="附件">
                        <upload-maintain
                          v-model="form.data.reportWarehouseAppendixUrls"
                          multiple
                          :limit="5"
                          dir="evaluation"
                          :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                        ></upload-maintain>
                      </en-form-item>
                    </en-form>
                  </en-card>

                  <en-card body-class="flex flex-col divide-x">
                    <div class="flex items-center justify-between">
                      <span>工单管理</span>
                    </div>

                    <en-form label-position="left" label-width="80">
                      <en-form-item label="分值">
                        <en-input :model-value="form.reportWorkOrdersScore" disabled></en-input>
                      </en-form-item>
                      <en-form-item label="达标状态">
                        <div class="grid grid-cols-2">
                          <span>上传及时性</span>
                          <en-radio-group
                            :model-value="(form.data.reportWorkOrders as Standard)?.CMIT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.reportWorkOrders.radio.change('CMIT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>无漏单现象</span>
                          <en-radio-group
                            :model-value="(form.data.reportWorkOrders as Standard)?.CMAWO"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.reportWorkOrders.radio.change('CMAWO', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>无漏钣面现象</span>
                          <en-radio-group
                            :model-value="(form.data.reportWorkOrders as Standard)?.CMASF"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.reportWorkOrders.radio.change('CMASF', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>无欠款现象</span>
                          <en-radio-group
                            :model-value="(form.data.reportWorkOrders as Standard)?.NODT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.reportWorkOrders.radio.change('NODT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>
                        </div>
                      </en-form-item>
                      <en-form-item label="附件">
                        <upload-maintain
                          v-model="form.data.reportWarehouseAppendixUrls"
                          multiple
                          :limit="5"
                          dir="evaluation"
                          :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                        ></upload-maintain>
                      </en-form-item>
                    </en-form>
                  </en-card>

                  <en-card body-class="flex flex-col divide-y">
                    <div class="flex items-center justify-between">
                      <span>现场管理</span>
                    </div>

                    <en-form label-position="left" label-width="80">
                      <en-form-item label="分值">
                        <en-input :model-value="form.workshopsScore" disabled></en-input>
                      </en-form-item>
                      <en-form-item label="达标状态">
                        <div class="grid grid-cols-2">
                          <span>及时整理</span>
                          <en-radio-group
                            :model-value="(form.data.workshops as Standard)?.ARIT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.workshops.radio.change('ARIT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>三不落地</span>
                          <en-radio-group
                            :model-value="(form.data.workshops as Standard)?.NOGD"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.workshops.radio.change('NOGD', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>定置管理</span>
                          <en-radio-group
                            :model-value="(form.data.workshops as Standard)?.LUMNG"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.workshops.radio.change('LUMNG', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>

                          <span>及时清扫</span>
                          <en-radio-group
                            :model-value="(form.data.workshops as Standard)?.CLUPIT"
                            :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                            @change="form.workshops.radio.change('CLUPIT', $event)"
                          >
                            <en-radio label="Y">是</en-radio>
                            <en-radio label="N">否</en-radio>
                          </en-radio-group>
                        </div>
                      </en-form-item>
                      <en-form-item label="附件">
                        <upload-maintain
                          v-model="form.data.reportWorkshopAppendixUrls"
                          multiple
                          :limit="5"
                          dir="evaluation"
                          :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_INTEGRATED"
                        ></upload-maintain>
                      </en-form-item>
                    </en-form>
                  </en-card>
                </en-scrollbar>
              </en-tab-pane>

              <en-tab-pane label="总结建议" name="suggestion">
                <en-form label-position="left" label-width="120">
                  <en-form-item label="分析及总结">
                    <en-input
                      v-model="form.data.summarizedComment"
                      type="textarea"
                      :rows="2"
                      :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_TECH_TRAINING"
                    ></en-input>
                  </en-form-item>
                  <en-form-item label="总结人">{{ form.data.summarizedBy?.name }}</en-form-item>
                  <en-form-item label="总结日期">{{ formatDate(form.data.summarizedDatetime) }}</en-form-item>
                </en-form>

                <en-table :data="form.data.problems" :loading="form.loading">
                  <en-table-column type="index" width="50"></en-table-column>
                  <en-table-column label="问题描述">
                    <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable }">
                      <en-input v-if="row.editable" v-model="row.description"></en-input>
                      <span v-else>{{ row.description }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="描述人" prop="raisedBy">
                    <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable }">
                      <en-input v-if="row.editable" v-model="row.raisedBy"></en-input>
                      <span v-else>{{ row.raisedBy }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="措施">
                    <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable }">
                      <en-input v-if="row.editable" v-model="row.solution"></en-input>
                      <span v-else>{{ row.solution }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="责任人">
                    <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable }">
                      <en-input v-if="row.editable" v-model="row.assignee"></en-input>
                      <span v-else>{{ row.assignee }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="预计完成时间">
                    <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable }">
                      <en-date-picker
                        v-if="row.editable"
                        v-model="row.expectedSettlingDate"
                        placeholder="选择日期时间"
                        type="date"
                        value-format="YYYY-MM-DD"
                        class="w-full"
                      ></en-date-picker>
                      <span v-else>{{ formatDate(row.expectedSettlingDate) }}</span>
                    </template>
                  </en-table-column>
                  <en-table-column label="操作">
                    <template
                      #default="{
                        row,
                        $index
                      }: {
                        row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable,
                        $index: number
                      }"
                    >
                      <en-button
                        type="primary"
                        link
                        :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_TECH_TRAINING"
                        @click="form.problems.delete.click($index)"
                      >
                        删除
                      </en-button>
                      <en-button
                        type="primary"
                        link
                        :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_TECH_TRAINING"
                        @click="form.problems.edit.click(row)"
                      >
                        编辑
                      </en-button>
                    </template>
                  </en-table-column>
                </en-table>

                <en-button
                  type="primary"
                  text
                  :disabled="form.disabled || !store.accessRightsHash.BUSINESS_EVALUATION_ENOCH_TECH_TRAINING"
                  @click="form.problems.add.click"
                >
                  添加问题描述
                </en-button>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>
</template>

<script lang="ts">
import { calculator, formatPercent } from '@enocloud/utils'
import { EnMessageBox } from '@enocloud/components'
import dayjs from 'dayjs'

interface ChartSourceData {
  label: string
  value: number
}

type Standard = Record<string, 'Y' | 'N'>

type Editable = { editable: boolean }

const EVALUATION_STANARD = {
  warehouses: {
    name: '仓储管理',
    standards: [
      {
        message: '出入库及时性',
        code: 'STIOIT',
        score: 40
      },
      {
        message: '本月是否盘库',
        code: 'STTIT',
        score: 20
      },
      {
        message: '仓库盘库正常',
        code: 'STRSTT',
        score: 20
      },
      {
        message: '调漆间盘库正常',
        code: 'WSHSTT',
        score: 20
      }
    ]
  },
  reportWorkOrders: {
    name: '工单管理',
    standards: [
      {
        message: '上传及时性',
        code: 'CMIT',
        score: 20
      },
      {
        message: '无漏单现象',
        code: 'CMAWO',
        score: 40
      },
      {
        message: '无漏钣面现象',
        code: 'CMASF',
        score: 40
      },
      {
        message: '无欠款现象',
        code: 'NODT',
        score: 0
      }
    ]
  },
  workshops: {
    name: '现场管理',
    standards: [
      {
        message: '及时整理',
        code: 'ARIT',
        score: 25
      },
      {
        message: '三不落地',
        code: 'NOGD',
        score: 25
      },
      {
        message: '定置管理',
        code: 'LUMNG',
        score: 25
      },
      {
        message: '及时清扫',
        code: 'CLUPIT',
        score: 25
      }
    ]
  }
}

export default factory({
  config: {
    computed: {
      isEnoch() {
        return this.store.userTenantId === 'ENOCH'
      }
    },

    children: {
      operation: {
        print: { click() {} },
        reverse: {
          async click() {
            await this.dirtyCheck('form')
            await this.form.reverse()
            return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
          }
        },
        commit: {
          async click() {
            await this.dirtyCheck('form')
            await this.form.commit()
            return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
          }
        },
        audit: {
          service: {
            async click() {
              await this.dirtyCheck('form')
              await this.form.serviceAudit()
              return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
            }
          },
          technical: {
            async click() {
              await this.dirtyCheck('form')
              await this.form.technicalAudit()
              return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
            }
          },
          management: {
            async click() {
              await this.dirtyCheck('form')
              await this.form.managementAudit()
              return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
            }
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
          }
        }
      },
      manifest: {
        row: {
          async click(row: EnosprayStatementDefinitions['PaintBusinessStatementQueryDto']) {
            this.form.init()
            this.form.data.id = row.id
            await this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()

            const startDate = dayjs().set('y', dayjs(this.form.data.endDate).get('y')).set('M', 0).set('D', 1).format('YYYY-MM-DD')
            const endDate = dayjs().set('y', dayjs(this.form.data.endDate).get('y')).set('M', 11).set('D', 31).format('YYYY-MM-DD')

            const businessData = await this.ajax(
              this.isEnoch ? 'GET /enospray/statement/paint/business/enoch/query' : 'GET /enospray/statement/paint/business/query',
              {
                payload: {
                  businessSettlementPolicy: this.form.data.businessSettlementPolicy?.code,
                  tenantId: this.form.data.tenant?.id,
                  startDate,
                  endDate
                }
              }
            )

            const reportData = await this.ajax(
              this.isEnoch ? 'GET /enospray/statement/paint/business/report/enoch/query' : 'GET /enospray/statement/paint/business/report/query',
              {
                payload: {
                  businessSettlementPolicy: this.form.data.businessSettlementPolicy?.code,
                  tenantId: this.form.data.tenant?.id,
                  startDate,
                  endDate
                }
              }
            )

            const normalizedBusinessData = Array.from({ length: 11 })
              .fill(undefined)
              .map((_, index) => {
                const matchData = businessData.data.filter((bd) => dayjs(bd.endDate).get('M') === index)
                return {
                  name: `${index + 1}月`,
                  totalSquare: matchData.reduce((sum, m) => calculator.add(sum, m.totalSquare), 0),
                  totalCount: matchData.reduce((sum, m) => calculator.add(sum, m.totalCount), 0),
                  attenCount: calculator.mul(matchData[0]?.totalCount, matchData[0]?.warrantyRate),
                  cost: matchData.reduce(
                    (sum, m) => calculator.add(m.totalAmount ? calculator.mul(calculator.div(m.primeCost, m.totalAmount), 100, 2) : 0, sum),
                    0
                  ),
                  primeCost: matchData[0]?.primeCost ?? 0,
                  totalAmount: matchData[0]?.totalAmount ?? 0,
                  warranty: matchData.reduce((sum, m) => calculator.add(sum, calculator.mul(m.warrantyRate, 100)), 0),
                  policy: matchData.reduce((sum, m) => calculator.add(sum, Math.abs(m.actualPolicyAmount ?? 0)), 0),
                  policyAdjustAmount: matchData.reduce((sum, m) => calculator.add(m.policyAdjustAmount, sum), 0),
                  autoCount: matchData.reduce((sum, m) => calculator.add(calculator.mul(m.scannerPercentage, 100, 2), sum), 0),
                  rework: matchData.reduce((sum, m) => calculator.add(sum, m.reworkCount), 0),
                  donateSquare: matchData.reduce((sum, m) => calculator.add(sum, m.donateSquare), 0)
                }
              })

            this.square.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.totalSquare }))

            this.cost.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.cost }))
            this.cost.totalPrimeCost = normalizedBusinessData.reduce((sum, m) => calculator.add(sum, m.primeCost), 0)
            this.cost.totalAmount = normalizedBusinessData.reduce((sum, m) => calculator.add(sum, m.totalAmount), 0)

            this.warranty.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.warranty }))
            this.warranty.totalCount = normalizedBusinessData.reduce((sum, m) => calculator.add(sum, m.totalCount), 0)
            this.warranty.attenCount = normalizedBusinessData.reduce((sum, m) => calculator.add(sum, m.attenCount), 0)

            this.policy.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.policy }))
            this.policyAdjust.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.policyAdjustAmount }))

            this.autoCount.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.autoCount }))

            this.rework.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.rework }))

            this.donate.data = normalizedBusinessData.map((item) => ({ label: item.name, value: item.donateSquare }))

            const normalizedReportData = Array.from({ length: 11 })
              .fill(undefined)
              .map((_, index) => {
                const matchData = reportData.data.filter((bd) => dayjs(bd.endDate).get('M') === index)
                return {
                  name: `${index + 1}月`,
                  score: matchData.reduce((sum, m) => calculator.add(sum, m.returnVisitScore), 0),
                  performance: matchData.reduce((sum, m) => calculator.add(sum, m.actualPerformanceAmount), 0),
                  adjust: matchData.reduce((sum, m) => calculator.add(sum, m.performanceAdjustAmount), 0)
                }
              })

            this.score.data = normalizedReportData.map((item) => ({ label: item.name, value: item.score }))

            this.performance.data = normalizedReportData.map((item) => ({ label: item.name, value: item.performance }))

            this.adjust.data = normalizedReportData.map((item) => ({ label: item.name, value: item.adjust }))
          }
        }
      },
      tabs: {
        active: 'report'
      },
      form: {
        ajax: {
          getByEnoch: {
            action: 'GET /enospray/statement/paint/business/report/enoch/:paintStatementReportId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          getByTenant: {
            action: 'GET /enospray/statement/paint/business/report/:paintStatementReportId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enospray/statement/paint/business/report/enoch',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          commit: {
            action: 'POST /enospray/statement/paint/business/report/enoch/:paintStatementReportId/commit',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          reverse: {
            action: 'POST /enospray/statement/paint/business/report/enoch/enoch/:paintStatementReportId/reverse',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          serviceAudit: {
            action: 'POST /enospray/statement/paint/business/report/enoch/audit/service',
            loading: true,
            params(params) {
              params.payload = { ids: [this.form.data.id] }
            }
          },
          technicalAudit: {
            action: 'POST /enospray/statement/paint/business/report/enoch/audit/technical',
            loading: true,
            params(params) {
              params.payload = { ids: [this.form.data.id] }
            }
          },
          managementAudit: {
            action: 'POST /enospray/statement/paint/business/report/enoch/audit/management',
            loading: true,
            params(params) {
              params.payload = { ids: [this.form.data.id] }
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.reportStatus && ['C', 'SA', 'TA', 'MA'].includes(this.form.data.reportStatus.code)
          },
          warehouseScore() {
            return EVALUATION_STANARD.warehouses.standards.reduce(
              (sum, item) => calculator.add(sum, (this.form.data.warehouses as any)?.[item.code] === 'Y' ? item.score : 0),
              0
            )
          },
          reportWorkOrdersScore() {
            return EVALUATION_STANARD.reportWorkOrders.standards.reduce(
              (sum, item) => calculator.add(sum, (this.form.data.reportWorkOrders as any)?.[item.code] === 'Y' ? item.score : 0),
              0
            )
          },
          workshopsScore() {
            return EVALUATION_STANARD.workshops.standards.reduce(
              (sum, item) => calculator.add(sum, (this.form.data.workshops as any)?.[item.code] === 'Y' ? item.score : 0),
              0
            )
          }
        },
        children: {
          warehouses: {
            radio: {
              change(code: string, value: string) {
                this.form.data.warehouses ??= {}
                ;(this.form.data.warehouses as any)[code] = value
              }
            }
          },
          reportWorkOrders: {
            radio: {
              change(code: string, value: string) {
                this.form.data.reportWorkOrders ??= {}
                ;(this.form.data.reportWorkOrders as any)[code] = value
              }
            }
          },
          workshops: {
            radio: {
              change(code: string, value: string) {
                this.form.data.workshops ??= {}
                ;(this.form.data.workshops as any)[code] = value
              }
            }
          },
          problems: {
            add: {
              click() {
                this.form.data.problems ??= []
                const init = { expectedSettlingDate: dayjs().format('YYYY-MM-DD'), editable: true, raisedBy: this.store.user.name }
                this.form.data.problems.push(init)
              }
            },
            delete: {
              click(index: number) {
                this.form.data.problems.splice(index, 1)
              }
            },
            edit: {
              click(row: EnosprayStatementDefinitions['PaintBusinessStatementReportProblemDto'] & Editable) {
                row.editable = true
              }
            }
          }
        }
      },
      square: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.totalSquare,
              average: calculator.div(
                this.square.data.reduce((sum, item) => calculator.add(sum, item.value), 0),
                this.square.data.filter((item) => item.value > 0).length
              )
            }
          }
        }
      },
      cost: {
        data: [] as ChartSourceData[],
        totalPrimeCost: 0,
        totalAmount: 0,
        computed: {
          summary() {
            const total = calculator.div(this.form.data.totalPrimeCost, this.form.data.totalAmount)
            const average = calculator.div(this.cost.totalPrimeCost, this.cost.totalAmount)
            let status = ''
            if (total <= 0.2) status = '达标'
            if (total > 0.2 && total < 0.23) status = `区间达标${formatPercent(calculator.mul(calculator.div(calculator.sub(0.23, total), 3), 100))}`
            if (total >= 0.23) status = '不达标'
            return { total, average, status }
          }
        },
        children: {
          check: {
            click() {
              const url = new URL('/enospray/summary/business/usagesummary', import.meta.env.VITE_ENOCLOUD_URL)
              url.searchParams.append('startDate', `${this.form.data.startDate}`)
              url.searchParams.append('endDate', `${this.form.data.endDate}`)
              url.searchParams.append('tenantId', `${this.form.data.tenant?.id}`)
              window.open(url)
            }
          }
        }
      },
      warranty: {
        data: [] as ChartSourceData[],
        totalCount: 0,
        attenCount: 0,
        computed: {
          summary() {
            return {
              total: this.form.data.warrantyRate,
              average: calculator.div(this.warranty.attenCount, this.warranty.totalCount),
              status: (this.form.data.warrantyRate ?? 0) >= 0.2 ? '达标' : '不达标'
            }
          }
        }
      },
      policy: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.actualPolicyAmount
            }
          }
        },
        children: {
          adjust: {
            async click() {
              const res = await EnMessageBox.prompt('请输入调整值', '商务政策', {
                inputPattern: /^[0-9]+\.{0,1}[0-9]{0,2}$/,
                inputErrorMessage: '请输入正确的调整值',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              })

              if (res.action === 'confirm') {
                await this.form.submit({ addition: { policyAdjustAmount: parseFloat(res.value) } })
                this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
              }
            }
          }
        }
      },
      policyAdjust: {
        data: [] as ChartSourceData[]
      },
      autoCount: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.scannerPercentage,
              average: calculator.div(
                this.autoCount.data.reduce((sum, item) => calculator.add(sum, item.value), 0),
                this.autoCount.data.filter((item) => item.value > 0).length,
                2
              ),
              status: ''
            }
          }
        }
      },
      rework: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.reworkCount,
              average: calculator.div(
                this.rework.data.reduce((sum, item) => calculator.add(sum, item.value), 0),
                this.rework.data.filter((item) => item.value > 0).length,
                2
              )
            }
          }
        }
      },
      donate: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.donateSquare
            }
          }
        }
      },
      score: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.reworkCount,
              average: calculator.div(
                this.rework.data.reduce((sum, item) => calculator.add(sum, item.value), 0),
                this.rework.data.filter((item) => item.value > 0).length,
                2
              )
            }
          }
        }
      },
      performance: {
        data: [] as ChartSourceData[],
        computed: {
          summary() {
            return {
              total: this.form.data.actualPerformanceAmount
            }
          }
        },
        children: {
          adjust: {
            async click() {
              const res = await EnMessageBox.prompt('请输入调整值', '团队绩效', {
                inputPattern: /^[0-9]+\.{0,1}[0-9]{0,2}$/,
                inputErrorMessage: '请输入正确的调整值',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              })

              if (res.action === 'confirm') {
                await this.form.submit({ addition: { performanceAdjustAmount: parseFloat(res.value) } })
                this.form[this.isEnoch ? 'getByEnoch' : 'getByTenant']()
              }
            }
          }
        }
      },
      adjust: {
        data: [] as ChartSourceData[]
      },
      dialog: {
        visible: false
      }
    }
  }
})
</script>
